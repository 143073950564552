.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* YourComponent.css */

.slide-left {
  animation: slideLeft 1s ease forwards;
  opacity: 0; /* Initial opacity */
}

.slide-right {
  animation: slideRight 1s ease forwards;
  opacity: 0; /* Initial opacity */
}

@keyframes slideLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


/* Default background image for larger screens */
.hero {
  min-height: 100vh;
  background-image: url('../public/background.jpg'); /* Use relative path */
  background-size: cover;
  background-position: center;
  opacity: 0.9;
}

/* Media query for smaller screens (e.g., phones) */
@media (max-width: 768px) {
  .hero {
    background-image: url('../public/background1.jpg'); /* Use relative path */
  }
}

